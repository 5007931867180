import React, { useEffect, useState } from 'react';
import { View, Animated, Dimensions, Text } from 'react-native';
import AppText from './AppText';
import colors from '../colors/colors';
const { width, height } = Dimensions.get('screen');

function PodcastSzachowyAnimation({ style }) {

    const valueLayout = useState(new Animated.ValueXY({ x: 0, y: 0 }))[0];
    const valueLayout2 = useState(new Animated.ValueXY({ x: 0, y: 0 }))[0];
    const valueLayout3 = useState(new Animated.ValueXY({ x: 0, y: 0 }))[0];



    return (
        <View style={[{ width: width > 650 ? width / 1.5 : 200, height: 700, overflow: 'hidden', justifyContent: 'center', alignItems: 'center', zIndex: 1 }, style]}>

            <View style={{ width: width > 650 ? width / 1.5 : 200, justifyContent: 'center', alignItems: 'center', height: 190 }}>

                <View style={{ width: width > 650 ? width / 1.5 : 200, justifyContent: 'center', alignItems: 'center', height: 190, position: 'absolute' }}>


                    <Animated.View style={[valueLayout.getLayout(), { width: width / 1.5, justifyContent: 'center', alignItems: 'center', height: 190 }]}>


                        <AppText text={"Podcast"} right={false} gradient={true} gradientSize={width > 1200 ? 200 : 150} style={{ fontSize: width > 650 ? 200 : 100, color: colors.white, textAlign: 'left', width: '100%' }} />

                    </Animated.View>

                </View>
            </View>

            <View style={{ width: width > 650 ? width / 1.5 : 200, justifyContent: 'center', alignItems: 'center', height: 190 }}>

                <View style={{ width: width > 650 ? width / 1.5 : 200, justifyContent: 'center', alignItems: 'center', height: 190, position: 'absolute' }}>


                    <Animated.View style={[valueLayout2.getLayout(), { width: width > 650 ? width / 1.5 : 200, justifyContent: 'center', alignItems: 'center', height: 190 }]}>

                        <AppText text={"  Szachowy"} gradient={true} gradientSize={width > 1200 ? 200 : 150} right={false} style={{ fontSize: width > 650 ? 190 : 100, color: colors.white, textAlign: 'right', width: '100%' }} />

                    </Animated.View>

                </View>


            </View>

            <View style={{ width: width > 650 ? width / 1.5 : 200, justifyContent: 'center', alignItems: 'center', height: 100 }}>

                <View style={{ width: width > 650 ? width / 1.5 : 200, justifyContent: 'center', alignItems: 'center', height: 100, position: 'absolute' }}>


                    <Animated.View style={[valueLayout3.getLayout(), { width: width > 650 ? width / 1.5 : 200, justifyContent: 'center', alignItems: 'center', height: 100 }]}>

                        <AppText text={"ARCYMISTRZOWSKI PODCAST MATEUSZA BARTLA"} gradient={true} gradientSize={width > 1200 ? 30 : 30} right={false} style={{ fontSize: width > 650 ? 190 : 100, color: colors.white, textAlign: 'right', width: '100%' }} />

                    </Animated.View>

                </View>


            </View>

        </View>
    );
}

export default PodcastSzachowyAnimation;