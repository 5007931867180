import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View } from 'react-native';
import MainScreen from './app/screens/MainScreen';
import ChessScreen from './app/screens/ChessScreen';
import { useWindowDimensions } from 'react-native';
import { useEffect } from 'react';

export default function App() {



  return (
    <ChessScreen />
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
