import React, { useEffect, useState } from 'react';
import { View, Animated, Dimensions, Text, Image } from 'react-native';
import AppText from './AppText';
import colors from '../colors/colors';
const { width, height } = Dimensions.get('screen');

function PodcastSzachowyAnimationMobile({ style }) {

    const valueLayout = useState(new Animated.ValueXY({ x: 0, y: 0 }))[0];
    const valueLayout2 = useState(new Animated.ValueXY({ x: 0, y: 0 }))[0];

    const valueLayout3 = useState(new Animated.ValueXY({ x: 0, y: 0 }))[0];

    const moveCard3 = () => {


        setTimeout(function () { Animated.timing(valueLayout, { toValue: { x: width + 50, y: 0 }, duration: 1000, useNativeDriver: false }).start(); }, 150);
        setTimeout(function () { Animated.timing(valueLayout2, { toValue: { x: -width - 50, y: 0 }, duration: 1000, useNativeDriver: false }).start(); }, 600);
        setTimeout(function () { Animated.timing(valueLayout3, { toValue: { x: width + 50, y: 0 }, duration: 1000, useNativeDriver: false }).start(); }, 950);
    }

    useEffect(() => { moveCard3() }, [])
    return (
        <View style={[{ width: width - 50, height: 500, overflow: 'hidden', alignItems: 'center', zIndex: 1, paddingTop: 30 }, style]}>

            <View style={{ width: width - 50, justifyContent: 'center', alignItems: 'center', height: 100 }}>

                <View style={{ width: width - 50, justifyContent: 'center', alignItems: 'center', height: 100, position: 'absolute', left: -width - 50 }}>


                    <Animated.View style={[valueLayout.getLayout(), { width: width - 50, justifyContent: 'center', alignItems: 'center', height: 100 }]}>


                        <AppText text={"Podcast"} center={false} gradient={true} gradientSize={70} style={{ fontSize: width > 650 ? 200 : 100, color: colors.white, width: '100%' }} />

                    </Animated.View>

                </View>
            </View>

            <View style={{ width: width - 50, justifyContent: 'center', alignItems: 'center', height: 100 }}>

                <View style={{ width: width - 50, justifyContent: 'center', alignItems: 'center', height: 100, position: 'absolute', right: -width - 50 }}>


                    <Animated.View style={[valueLayout2.getLayout(), { width: width - 50, justifyContent: 'center', alignItems: 'center', height: 100 }]}>

                        <AppText text={"  Szachowy"} gradient={true} gradientSize={70} center={true} style={{ fontSize: width > 650 ? 190 : 100, color: colors.white, width: '100%' }} />

                    </Animated.View>

                </View>


            </View>

            <View style={{ width: width - 50, justifyContent: 'center', alignItems: 'center', height: 100 }}>

                <View style={{ width: width - 50, justifyContent: 'center', alignItems: 'center', height: 100, position: 'absolute', left: -width - 50 }}>


                    <Animated.View style={[valueLayout3.getLayout(), { width: width - 50, justifyContent: 'center', alignItems: 'center', height: 100 }]}>

                        <Image style={{ width: 100, height: 100, borderRadius: 20 }} source={require("../assets/logo.png")} />

                    </Animated.View>

                </View>


            </View>


        </View>
    );
}

export default PodcastSzachowyAnimationMobile;