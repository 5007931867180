import React, { useEffect, useRef, useState } from 'react';
import { View, Text, TouchableOpacity, ScrollView, Dimensions, Image, Animated, TextInput, StyleSheet, Platform, Alert, Linking, Pressable, FlatList } from 'react-native';
import colors from '../colors/colors';
import PodcastSzachowyAnimation from '../components/PodcastSzachowyAnimation';
import { Entypo, MaterialCommunityIcons } from '@expo/vector-icons';
import TabButton from '../components/TabButton';
import AppText from '../components/AppText';
const { height } = Dimensions.get('screen');
import { LinearGradient } from 'expo-linear-gradient';
import AppTextInput from '../components/AppTextInput';
import LargeTextInput from '../components/LargeTextInput';
import { db, firebase, storage, reference } from '../config/config';
import { set, get, onValue, push, update, remove } from 'firebase/database';
import QuestionAnimation from '../components/QuestionAnimation';
import { Chessboard } from "react-chessboard";
import { Chess } from "chess.js";
import PodcastSzachowyAnimationMobile from '../components/PodcastSzachowyAnimationMobile';
import { useFonts } from 'expo-font';
import NewestEpisode from '../components/NewestEpisode';
import Lottie from "lottie-react";
import Puzzle from '../components/Puzzle';

const width = window.innerWidth;

const generalRound = 14;

const tasks = [


    { id: 1, question: "Białe dają mata w Jednym ruchu.", level: 'ŁATWY', position: 'r1bqkb1r/pppp1ppp/2n2n2/4p2Q/2B1P3/8/PPPP1PPP/RNB1K1NR w KQkq - 0 1', solution: 'r1bqkb1r/pppp1Qpp/2n2n2/4p3/2B1P3/8/PPPP1PPP/RNB1K1NR b KQkq - 0 1' },

    { id: 2, question: "Białe dają mata w Jednym ruchu.", level: 'ŚREDNI', position: '5k2/5ppp/8/n5B1/7P/1q4P1/5P2/3R2K1 w - - 0 1', solution: '3R1k2/5ppp/8/n5B1/7P/1q4P1/5P2/6K1 b - - 1 1' },

    { id: 3, question: "Białe dają mata w Jednym ruchu.", level: 'TRUDNY', position: 'qkrn3r/ppp1b3/5nb1/8/1N6/4NP2/Q3PBP1/1R3K2 w - - 0 1', solution: 'qkrn3r/ppp1b3/N4nb1/8/8/4NP2/Q3PBP1/1R3K2 b - - 1 1' },

    { id: 4 }

]

const ChessScreen = () => {
    const scrollViewRef = useRef(null);
    const puzzlesListRef = useRef(null);

    const [id, setId] = useState(Math.floor(Math.random() * 999999999999999) + 1);
    const [moves, setMoves] = useState([]);


    const [fontsLoaded] = useFonts({
        'AppFont': require("../fonts/WebsiteFont.ttf"),
    });


    const [bestMove, setBestMove] = useState();



    useEffect(() => {


        const starCountRef = reference(db, "moves/");
        onValue(starCountRef, (snapshot) => {
            const data = snapshot.val();
            var newPosts = Object.keys(data).map(key => ({

                id: key,
                ...data[key],

            }));


            setMoves(newPosts.filter(item => item.round == generalRound));

            const groupedMoves = newPosts.filter(item => item.round == generalRound).reduce((groups, move) => {
                const groupKey = `${move.piece}-${move.move}`;

                if (groups[groupKey]) {
                    groups[groupKey].count += 1;
                } else {
                    groups[groupKey] = { piece: move.piece, move: move.move, count: 1, id: move.id };
                }

                return groups;
            }, {});

            // Przechodzimy przez zgrupowane ruchy i zwiększamy pole 'move' o 1
            for (const groupKey in groupedMoves) {
                groupedMoves[groupKey].move += 1;
            }

            const groupedMovesArray = Object.values(groupedMoves);


            const itemWithMaxCount = groupedMovesArray.reduce((maxItem, currentItem) => {
                if (currentItem.count > maxItem.count) {
                    return currentItem;
                } else {
                    return maxItem;
                }
            });


            const polishPieceName = itemWithMaxCount.piece === "p" ? "" : (itemWithMaxCount.piece === "n" ? "S" : (itemWithMaxCount.piece === "r" ? "W" : (itemWithMaxCount.piece === "q" ? "H" : (itemWithMaxCount.piece === "k" ? "K" : (itemWithMaxCount.piece === "b" ? "G" : "")))));


            setBestMove((polishPieceName + String(itemWithMaxCount.move).slice(0, -1)))

        })
    }, [])



    const scrollToSection = (sectionId) => {
        const yOffset = sections[sectionId].offsetTop;
        scrollViewRef.current.scrollTo({ y: yOffset, animated: true });
    };

    const sections = {
        section1: { id: 'section1', offsetTop: window.innerHeight },
        section2: { id: 'section2', offsetTop: window.innerHeight + 300 },
        section3: { id: 'section3', offsetTop: window.innerHeight + 300 + 900 },
        section4: { id: 'section4', offsetTop: window.innerHeight + 300 + 900 + 1500 },
        section5: { id: 'section5', offsetTop: 2000 },
    };

    const valueLayout = useState(new Animated.ValueXY({ x: 0, y: 0 }))[0];

    const valueLayout2 = useState(new Animated.ValueXY({ x: 0, y: 0 }))[0];

    const valueLayout3 = useState(new Animated.ValueXY({ x: 0, y: 0 }))[0];

    const valueLayout4 = useState(new Animated.ValueXY({ x: 0, y: 0 }))[0];

    const moveCard3 = () => {


        setTimeout(function () { Animated.timing(valueLayout, { toValue: { x: 0, y: width }, duration: 300, useNativeDriver: false }).start(); }, 150);
    }

    const moveCardMakeMove = () => {


        Animated.timing(valueLayout3, { toValue: { x: 0, y: -200 }, duration: 500, useNativeDriver: false }).start();
    }

    const moveCardPieceSelected = (piece) => {

        setPiece(piece)
        Animated.timing(valueLayout4, { toValue: { x: -width / 3, y: 0 }, duration: 500, useNativeDriver: false }).start();
    }

    const sentMoveCard = (piece) => {

        Animated.timing(valueLayout3, { toValue: { x: 0, y: -550 }, duration: 500, useNativeDriver: false }).start();
        setTimeout(function () { Animated.timing(valueLayout3, { toValue: { x: 0, y: 0 }, duration: 500, useNativeDriver: false }).start(); }, 3000);
    }

    const moveCardRules = () => {

        Animated.timing(valueLayout2, { toValue: { x: width, y: 0 }, duration: 500, useNativeDriver: false }).start();
    }

    const moveCardStatistics = () => {

        Animated.timing(valueLayout2, { toValue: { x: -width, y: 0 }, duration: 500, useNativeDriver: false }).start();
    }

    const moveCardPlay = () => {

        Animated.timing(valueLayout2, { toValue: { x: 0, y: 0 }, duration: 500, useNativeDriver: false }).start();

    }

    const [name, setName] = useState('');
    const [question, setQuestion] = useState('');
    const [sent, setSent] = useState('WYŚLIJ')

    const [move, setMove] = useState()

    const sendQuestion = () => {



        if (question !== '' && name !== '') {
            set(reference(db, "questions/" + id,), {
                id: id,
                name: name,
                question: question,
                date: "dzien : " + (new Date().getDate()) + " miesiac " + (new Date().getMonth() + 1),




            }).then(() => setSent("WYSŁANO!")).then(() => setName("Dziękujemy")).then(() => setQuestion("Za wysyłanie!"));
        }
        else
            Alert.alert('Wypełnij wszyskie pola');

    }

    const [piece, setPiece] = useState();

    const makeMove = () => {

        const moveId = Math.floor(Math.random() * 999999999999999) + 1;

        if (move !== '') {
            set(reference(db, "moves/" + moveId,), {
                id: moveId,
                piece: piece,
                move: move.toLowerCase(),
                round: generalRound




            }).then(() => { sentMoveCard() })
        }


    }







    const Chart = () => {
        const groupedMovesArray = Object.values(moves);
        const totalSum = groupedMovesArray.reduce((sum, item) => sum + item.count, 0);
        const barWidth = 80; // Szerokość każdego słupka
        const spacing = 10; // Odstęp między słupkami



        return (
            <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                {groupedMovesArray.map((item) => {

                    const barHeight = (item.count / totalSum) * 100;

                    const style = {
                        width: barWidth,
                        height: barHeight + '%',
                        backgroundColor: colors.white,
                        marginHorizontal: spacing,
                        overflow: 'hidden',
                        justifyContent: 'center',
                        alignItems: 'center'
                    };


                    return (
                        <View key={item.id} style={style}>
                            <LinearGradient
                                colors={['#FF5733', '#A83279']} // Kolorystyka gradientu
                                // Punkt końcowy gradientu (x, y)
                                style={{ height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center', position: 'absolute' }}
                            >

                                <AppText text={Math.round(barHeight) + "%"} style={{ color: colors.white, fontSize: barHeight < 10 ? 11 : 16 }} />
                                <AppText text={item.piece + String(item.move).replace("1", "")} style={{ color: colors.white, fontSize: barHeight < 10 ? 11 : 16 }} />

                            </LinearGradient>
                        </View>
                    );
                })}
            </View>
        );
    };




    const [lastMove, setLastMove] = useState();
    const [game, setGame] = useState(new Chess("r3kb1r/pp1b1p1p/3qp2p/3p4/8/P1NB4/1P3PPP/R2QR1K1 w kq - 0 9"));

    function makeAMove(move) {
        const gameCopy = new Chess(game.fen());
        const result = gameCopy.move(move);
        if (result !== null) {
            setLastMove(move);

            // Wydrukuj informacje o ruchu na konsolę
            const piece = gameCopy.get(move.to); // Pobierz figurę z pola docelowego ruchu
            if (piece) {

                const moveId = Math.floor(Math.random() * 999999999999999) + 1;


                set(reference(db, "moves/" + moveId,), {
                    id: moveId,
                    piece: piece.type,
                    move: move.to,
                    round: generalRound




                }).then(() => { sentMoveCard() })
            }

        }
        setGame(gameCopy);
        return result;
    }

    function makeRandomMove() {
        const possibleMoves = game.moves({ verbose: true });

        // Wybierz tylko ruchy białych figurek
        const whiteMoves = possibleMoves.filter((move) => move.color === "w");

        if (
            game.game_over() ||
            game.in_draw() ||
            whiteMoves.length === 0
        ) {
            return; // Wyjście, jeśli gra się skończyła lub brak ruchów białych
        }

        const randomIndex = Math.floor(Math.random() * whiteMoves.length);
        makeAMove(whiteMoves[randomIndex]);
    }

    function onDrop(sourceSquare, targetSquare) {
        // Sprawdź, czy figura jest białą figurą
        const piece = game.get(sourceSquare);
        if (!piece || piece.color !== "w") {
            return false;
        }
        const move = makeAMove({
            from: sourceSquare,
            to: targetSquare,
            promotion: "q", // Zawsze promuj do królowej dla uproszczenia
        });

        // Niepoprawny ruch
        if (move === null) {
            // Figura wraca na pole źródłowe
            makeAMove({
                from: targetSquare,
                to: sourceSquare,
                promotion: "q",
            });
            return false;
        }
        setTimeout(makeRandomMove, 200);
        return true;
    }

    const [scrollPostion, setScrollPosition] = useState(0);

    var test = 1;

    /** useEffect(() => {
        const handleMouseMove = (event) => {
            setMousePos({ x: event.clientX, y: scrollPostion - (3400 + window.innerHeight) + event.clientY, yOffetBubble: event.clientY })
        };




        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, [scrollPostion]);*/







    const [viewPosition, setViewPosition] = useState({ x: 0, y: 0 });

    const handleLayout = (event) => {
        const { x, y } = event.nativeEvent.layout;
        setViewPosition({ x, y });
    };

    const [widthOfNextSlide, setWidthOfNextSlide] = useState(new Animated.Value(0));
    const [heightOfNextSlide, setHeightOfNextSlide] = useState(new Animated.Value(0));

    const createTextOpacity = useState(new Animated.Value(1))[0];

    const toogleWidths = () => {
        Animated.spring(widthOfNextSlide, {
            toValue: 120,
            duration: 300,
            useNativeDriver: false,
        }).start()

        Animated.spring(heightOfNextSlide, {
            toValue: 120,
            duration: 300,
            useNativeDriver: false,
        }).start()



    }

    const toogleWidthsOff = () => {
        Animated.spring(widthOfNextSlide, {
            toValue: 0,
            duration: 200,
            useNativeDriver: false,
        }).start()

        Animated.spring(heightOfNextSlide, {
            toValue: 0,
            duration: 200,
            useNativeDriver: false,
        }).start()




    }

    const [mousePos, setMousePos] = useState({ y: 0, x: 0, yOffetBubble: 0, scrollPosition: 0 });

    var [index, setIndex] = useState(0);





    const scrollToPreviousPuzzle = () => {

        if (index >= 1) {
            setIndex(index - 1)
        }

    }

    const viewOpacity = useState(new Animated.Value(0))[0];

    useEffect(() => {


        setTimeout(function () {
            Animated.timing(viewOpacity, {
                toValue: 1,
                duration: 1000,
                useNativeDriver: true,
            }).start();
        }, 200)


    }, [])

    return (






        <ScrollView ref={scrollViewRef} scrollEventThrottle={1} onScroll={(x) => {
            //setMousePos({ x: mousePos.x, y: x.nativeEvent.contentOffset.y - (3400 + window.innerHeight) + mousePos.yOffetBubble, yOffetBubble: mousePos.yOffetBubble, })
            //setScrollPosition(x.nativeEvent.contentOffset.y);
        }} style={{ backgroundColor: colors.black }}>
            <Animated.View style={{ width: window.innerWidth, height: window.innerHeight, opacity: viewOpacity }}>



                <View style={{ width: window.innerWidth, height: width > 650 ? window.innerHeight : 400, alignItems: 'center', overflow: 'hidden', backgroundColor: colors.black }} onPress={() => scrollToSection('section3')}>

                    {width > 650 && <Image style={{ width: 600, height: '100%', position: 'absolute', right: 0, bottom: 0 }} source={require("../assets/mateuszBartel.png")} />}



                    {width > 650 && <Image style={{ width: 100, height: 100, borderRadius: 20, position: 'absolute', top: 20, left: 40, opacity: 0.6 }} source={require("../assets/logo.png")} />}

                    <View style={{ width: width, justifyContent: 'center', alignItems: 'center', height: 60, position: 'absolute', bottom: 50 }}>



                    </View>
                    {width > 650 && <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 20, height: 80, zIndex: 1 }}>

                        <TabButton width={200} text={"POSŁUCHAJ NAS"} onPress={() => scrollToSection('section1')} />

                        <TabButton width={170} text={"ARCYPYTANIA"} onPress={() => scrollToSection('section2')} />

                        <TabButton width={220} text={"PARTIA SZACHOWA"} onPress={() => scrollToSection('section3')} />

                        <TabButton width={150} text={"O NAS"} onPress={() => scrollToSection('section4')} />

                    </View>}


                    <View style={{ width: '100%', height: '65%' }}>
                        {width > 650 && <View style={{ width: width / 1.5, height: '100%', position: 'absolute', left: 30 }}>

                            <PodcastSzachowyAnimation />

                        </View>}

                        {width < 650 && <View style={{ width: window.innerWidth, height: '100%', alignItems: 'center', position: 'absolute', top: 0 }}>

                            <PodcastSzachowyAnimationMobile />

                        </View>}
                    </View>


                </View>



                <View style={{ height: width > 650 ? 420 : 300, backgroundColor: '#302f2f', paddingTop: 20, alignItems: 'center', borderTopWidth: 3, borderColor: colors.white }}>


                    <View style={{}}>
                        <AppText text={"SŁUCHAJ W:"} gradient={true} gradientSize={width < 650 ? 40 : 60} center={true} />

                    </View>
                    <View style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'row', width: '100%', height: width < 650 ? 200 : 300 }}>
                        <TouchableOpacity style={{ width: '33%', height: width < 650 ? 150 : 300, justifyContent: 'center', alignItems: 'center' }} onPress={() => Linking.openURL("https://open.spotify.com/show/0YmTd4ByFeSmdh9fklUOp3?si=bd81516312ae4750")}>


                            <Image style={{ width: '50%', height: 150, resizeMode: 'contain', marginBottom: width < 650 ? 0 : 15 }} source={{ uri: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/19/Spotify_logo_without_text.svg/2048px-Spotify_logo_without_text.svg.png" }} />
                            <AppText text={"SPOTIFY"} gradient={true} gradientSize={width < 650 ? 13 : 26} center={true} />
                        </TouchableOpacity>

                        <TouchableOpacity style={{ width: '33%', height: width < 650 ? 150 : 300, justifyContent: 'center', alignItems: 'center' }} onPress={() => Linking.openURL("https://podcasts.apple.com/pl/podcast/podcast-szachowy/id1705626363")}>


                            <Image style={{ width: '40%', height: 150, resizeMode: 'contain', marginBottom: width < 650 ? 0 : 15 }} source={{ uri: "https://seeklogo.com/images/A/apple-podcast-logo-0CF661058F-seeklogo.com.png" }} />
                            <AppText text={"APPLE PODCAST"} gradient={true} gradientSize={width < 650 ? 13 : 26} center={true} />
                        </TouchableOpacity>

                        <TouchableOpacity style={{ width: '33%', height: width < 650 ? 150 : 300, justifyContent: 'center', alignItems: 'center' }} onPress={() => Linking.openURL("https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy50cmFuc2lzdG9yLmZtL3BvZGNhc3Qtc3phY2hvd3k=")}>


                            <Image style={{ width: '40%', height: 150, resizeMode: 'contain', marginBottom: width < 650 ? 0 : 15 }} source={{ uri: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/25/Google_Podcasts_icon.svg/1200px-Google_Podcasts_icon.svg.png" }} />

                            <AppText text={"GOOGLE PODCAST"} gradient={true} gradientSize={width < 650 ? 13 : 26} center={true} />
                        </TouchableOpacity>
                    </View>
                </View>

                <View style={{ height: 900, backgroundColor: colors.black, paddingTop: 20, alignItems: 'center', borderTopWidth: 3, borderColor: colors.white }}>

                    <AppText text={"ARCYPYTANIA"} gradient={true} gradientSize={width > 650 ? 70 : 55} center={true} />
                    <AppText text={"ZADAJ PYTANIE - NA NAJCIEKAWSZE ODPOWIADAM W PODCAŚCIE"} gradient={true} gradientSize={width > 650 ? 20 : 15} center={true} />


                    <AppTextInput placeHolderName={"WPISZ SWOJĘ IMIĘ"} style={{ marginTop: 30 }} onChangeEvent={(text) => setName(text)} text={name} />

                    <View
                        style={{
                            backgroundColor: '#a69c9c',
                            borderBottomColor: '#000000',
                            borderBottomWidth: 1,
                            marginTop: 30,
                            borderRadius: 20,
                            width: width > 650 ? width / 2 : width - 50,
                            height: 450,
                            marginVertical: 30,
                            alignItems: 'center', justifyContent: 'center',
                            marginBottom: 30
                        }}>

                        <View style={{ marginTop: 20 }}>
                            <QuestionAnimation />
                            <AppText text={"WPISZ PYTANIE:"} gradient={true} gradientSize={20} center={true} />
                        </View>

                        <TextInput
                            editable
                            multiline
                            onChangeText={(text) => setQuestion(text)}
                            numberOfLines={4}
                            maxLength={3000}
                            placeholder='TREŚĆ PYTANIA'
                            style={[styles.textStyle, { padding: 10, }]}
                        />
                    </View>

                    <TabButton text={sent} width={250} onPress={() => sendQuestion()} />

                </View>



                <View style={{ height: width > 650 ? 1500 : 1100, backgroundColor: '#302f2f', paddingTop: 0, alignItems: 'center', overflow: 'hidden', borderTopWidth: 3, borderColor: colors.white }}>




                    <Animated.View style={[valueLayout2.getLayout(), { width: '300%', paddingTop: 20, alignItems: 'center', height: 1500, bordeRadius: 'center', flexDirection: 'row' }]}>

                        <View style={{ width: '33.3333%', height: '100%', alignItems: 'center' }}>



                            <AppText text={"ZASADY"} gradient={true} gradientSize={70} center={true} />
                            <AppText text={"OTO PROSTE ZASADY GRY :"} gradient={true} gradientSize={20} center={true} />

                            <View style={{ marginVertical: 50 }}>
                                <AppText text={"1.NIEUŻYWANIE STOCKFISHA (:"} style={{ color: colors.white, fontSize: 16 }} />
                            </View>

                            <View style={{ marginVertical: 50 }}>
                                <AppText text={"2. PROSIMY O PROPOZYCJĘ JEDNEGO POZUNIĘCIA NA ODCINEK"} style={{ color: colors.white, fontSize: 16 }} />
                            </View>
                            <TouchableOpacity style={{ height: 60, justifyContent: 'center', alignItems: 'center', borderRadius: 30, overflow: 'hidden', width: 60, marginHorizontal: 15 }} onPress={moveCardPlay}>
                                <LinearGradient
                                    colors={['#FF5733', '#A83279']} // Kolorystyka gradientu
                                    start={[0, 0]} // Punkt startowy gradientu (x, y)
                                    end={[1, 0]}   // Punkt końcowy gradientu (x, y)
                                    style={{ height: 60, width: 60, justifyContent: 'center', alignItems: 'center', position: 'absolute' }}
                                ></LinearGradient>

                                <MaterialCommunityIcons name='arrow-right' color={colors.white} size={30} style={{ zIndex: 1 }} />
                            </TouchableOpacity>












                        </View>


                        <View style={{ width: '33.3333%', height: '100%', alignItems: 'center', overflow: 'hidden', paddingTop: 20 }}>

                            <AppText text={"ZAGRAJ PARTIĘ"} gradient={true} gradientSize={width > 650 ? 70 : 55} center={true} />
                            <AppText text={"DOŁĄCZ DO GRY SŁUCHACZE VS GM MATEUSZ BARTEL"} gradient={true} gradientSize={width > 650 ? 20 : 15} center={true} />

                            {width > 650 && <View style={{ width: width > 650 ? width / 2 : width - 50, alignItems: 'center', justifyContent: 'center', height: 100, marginTop: 50 }}>

                                <View style={{ position: 'absolute', left: 0, flexDirection: 'row' }}>


                                    <Image style={{ width: 100, height: 100, borderRadius: 50, marginHorizontal: 20 }} source={{ uri: "https://upload.wikimedia.org/wikipedia/commons/a/a4/2022-Mateusz-Bartel.JPG" }} />

                                    <View style={{ height: 60 }}>

                                        <AppText text={"GM MATEUSZ BARTEL"} style={{ fontSize: 25, color: colors.white }} />
                                        <AppText text={"2625 RANKINGU FIDE"} style={{ color: colors.white, textAlign: 'left', fontSize: 18 }} />
                                    </View>
                                </View>


                            </View>}


                            {width < 650 && <View style={{ width: width > 650 ? width / 2 : width - 50, justifyContent: 'center', height: 100, marginTop: 50 }}>

                                <View style={{ position: 'absolute', left: 0, flexDirection: 'row' }}>


                                    <Image style={{ width: 70, height: 70, borderRadius: 50, marginHorizontal: 10 }} source={{ uri: "https://upload.wikimedia.org/wikipedia/commons/a/a4/2022-Mateusz-Bartel.JPG" }} />

                                    <View style={{ height: 40 }}>

                                        <AppText text={"GM MATEUSZ BARTEL"} style={{ fontSize: 20, color: colors.white }} />
                                        <AppText text={"2625 RANKINGU FIDE"} style={{ color: colors.white, textAlign: 'left', fontSize: 13 }} />
                                    </View>
                                </View>


                            </View>}

                            <View style={{ width: width > 650 ? width / 2.5 : width - 50, height: width > 650 ? width / 2.5 : width - 50, marginVertical: 30 }} >
                                <Chessboard position={game.fen()} onPieceDrop={onDrop} />
                            </View>

                            {width > 650 && <View style={{ width: width / 2, alignItems: 'center', justifyContent: 'center', height: 100, marginBottom: 30 }}>

                                <View style={{ position: 'absolute', right: 0, flexDirection: 'row' }}>




                                    <View style={{ height: 60 }}>

                                        <AppText text={"GM SŁUCHACZE"} style={{ fontSize: 25, color: colors.white }} />
                                        <AppText text={"3200 RANKINGU FIDE"} style={{ color: colors.white, textAlign: 'left', fontSize: 18 }} />
                                    </View>
                                    <Image style={{ width: 100, height: 100, borderRadius: 50, marginHorizontal: 20 }} source={require("../assets/logo.png")} />
                                </View>


                            </View>}

                            {width < 650 && <View style={{ width: width / 2, alignItems: 'center', justifyContent: 'center', height: 100, marginBottom: 30 }}>

                                <View style={{ position: 'absolute', right: 0, flexDirection: 'row' }}>




                                    <View style={{ height: 40 }}>

                                        <AppText text={"GM SŁUCHACZE PS"} style={{ fontSize: 20, color: colors.white }} />
                                        <AppText text={"3200 RANKINGU FIDE"} style={{ color: colors.white, textAlign: 'left', fontSize: 13 }} />
                                    </View>
                                    <Image style={{ width: 70, height: 70, borderRadius: 50, marginHorizontal: 10 }} source={require("../assets/logo.png")} />
                                </View>


                            </View>}

                            <AppText text={"ODDAJ GŁOS"} gradient={true} gradientSize={55} center={true} />
                            <AppText text={"ZAPROPONUJ SWÓJ RUCH WYKONUJĄC GO NA SZACOWNICY"} gradient={true} gradientSize={18} center={true} />




                            {width > 650 && <View style={{ width: '10%', height: '100%', position: 'absolute', right: 20, justifyContent: 'center', alignItems: 'center' }}>

                                <TouchableOpacity style={{ alignItems: 'center' }} onPress={() => moveCardStatistics()}>

                                    <MaterialCommunityIcons name='chess-pawn' color={colors.white} size={60} style={{ marginBottom: 10 }} />
                                    <AppText text={"OBECNY RUCH"} gradient={true} gradientSize={15} center={true} />

                                </TouchableOpacity>


                            </View>}

                            {width < 650 && <View style={{ justifyContent: 'center', alignItems: 'center', marginTop: 30 }}>

                                <TouchableOpacity style={{ alignItems: 'center' }} onPress={() => moveCardStatistics()}>

                                    <MaterialCommunityIcons name='chess-pawn' color={colors.white} size={50} style={{ marginBottom: 10 }} />
                                    <AppText text={"OBECNY RUCH"} gradient={true} gradientSize={15} center={true} />
                                </TouchableOpacity>


                            </View>}





                        </View>

                        <View style={{ width: '33.3333%', height: '100%', alignItems: 'center', overflow: 'hidden', paddingTop: 20 }}>



                            <AppText text={"STATYSTYKI"} gradient={true} gradientSize={width > 650 ? 70 : 45} center={true} />
                            <AppText text={"ZOBACZ STATYSTYKI PROPOZYCJI POSUNIĘĆ"} gradient={true} gradientSize={width > 650 ? 20 : 17} center={true} />

                            <View style={{ width: width > 650 ? '100%' : '90%', height: 750, alignItems: 'center', justifyContent: 'center' }}>


                                <AppText text={bestMove} gradient={true} gradientSize={width > 650 ? 200 : 130} center={true} />

                                <AppText text={"OBECNIE NAJCZĘŚCIEJ WYBIERANY RUCH"} gradient={true} gradientSize={width > 650 ? 50 : 20} center={true} />



                            </View>

                            <TouchableOpacity style={{ height: 60, justifyContent: 'center', alignItems: 'center', borderRadius: 30, overflow: 'hidden', width: 60, marginHorizontal: 15 }} onPress={moveCardPlay}>
                                <LinearGradient
                                    colors={['#FF5733', '#A83279']} // Kolorystyka gradientu
                                    start={[0, 0]} // Punkt startowy gradientu (x, y)
                                    end={[1, 0]}   // Punkt końcowy gradientu (x, y)
                                    style={{ height: 60, width: 60, justifyContent: 'center', alignItems: 'center', position: 'absolute' }}
                                ></LinearGradient>

                                <MaterialCommunityIcons name='arrow-left' color={colors.white} size={30} style={{ zIndex: 1 }} />
                            </TouchableOpacity>




                        </View>



                    </Animated.View>

                    <View style={{ width: width > 650 ? width / 3 : width - 30, justifyContent: 'center', height: 140, position: 'absolute', bottom: -140, zIndex: 1 }}>


                        <Animated.View style={[valueLayout3.getLayout(), { width: width > 650 ? width / 3 : width - 30, overflow: 'hidden', height: 140, bordeRadius: 'center', borderRadius: 30, backgroundColor: '#a69c9c', paddingTop: 10 }]}>


                            <View style={{ width: '100%', height: '100%', alignItems: 'center' }}>

                                <Lottie animationData={require("../assets/animations/move.json")} loop={true} style={{ width: 100, height: 100 }} />

                                <AppText text={"RUSZONE!"} gradient={true} gradientSize={20} center={true} />



                            </View>

                        </Animated.View>

                    </View>

                </View>

                <View style={{ height: width > 650 ? 400 : 450, backgroundColor: colors.black, alignItems: 'center', paddingTop: width < 650 ? 30 : 20, borderTopWidth: 3, borderColor: colors.white }}>

                    <AppText text={"KIM JESTEŚMY?"} gradient={true} gradientSize={width > 650 ? 70 : 55} center={true} />
                    <View style={{ width: width > 650 ? width / 1.5 : width - 20 }}>
                        <AppText text={`Witaj w świecie, gdzie każdy ruch ma znaczenie, a strategia staje się sztuką! `} gradient={true} gradientSize={width > 650 ? 20 : 16} center={true} />

                        <View style={{ marginVertical: 30 }}>
                            <AppText text={`Arcymistrz Mateusz Bartel zaprasza do fascynującej podróży przez szachowe królestwo - przygotuj się na pełne napięcia partie, fascynujące historie i inspirujące rozmowy. Podcast Szachowy zagląda za kulisy profesjonalnych i amatorskich rozgrywek sprawdzając jak szachy wpływają na myślenie, decyzje i kreatywność. Sięgamy też do historii - przeniesiemy Cię w czasie, byś mógł poznać kluczowe momenty i legendarnych graczy, którzy na zawsze zmienili oblicze szachów na świecie.
`} gradient={true} gradientSize={width > 650 ? 20 : 16} center={true} />
                        </View>

                        <View style={{ marginVertical: 0 }}>
                            <AppText text={` Bez względu na to, czy masz tytuł arcymistrza, czy tylko znasz zasady – Podcast Szachowy jest właśnie dla Ciebie.
`} gradient={true} gradientSize={width > 650 ? 20 : 16} center={true} />
                        </View>
                    </View>

                </View>

                { /** <>
                <View style={{ width: width, backgroundColor: '#302f2f', borderTopColor: colors.white, borderTopWidth: 4, paddingTop: 30 }}>

                    <View style={{ height: 150, alignItems: 'center' }}>
                        <AppText text={"ZAGADKI"} gradient={true} gradientSize={width < 650 ? 55 : 70} center={true} />

                        <AppText text={"ROZWIĄŻ PUZZLE Z NAJNOWSZEGO ODCINKA"} gradient={true} gradientSize={width > 650 ? 20 : 15} center={true} />

                    </View>

                    <View style={{ width: width, justifyContent: 'center', alignItems: 'center' }}>
                        <Pressable style={{ width: width, height: height, overflow: 'visible', position: 'absolute', top: 0 }} onHoverIn={() => toogleWidths()} onHoverOut={() => toogleWidthsOff()} onLayout={handleLayout}>


                            {mousePos.y != 0 &&

                                <TouchableOpacity style={{ position: 'absolute', top: mousePos.y - 60, left: mousePos.x - 60, zIndex: 1, overflow: 'hidden' }} onPress={() => scrollToNewPuzzle()}>
                                    <Animated.View style={{ width: widthOfNextSlide, height: heightOfNextSlide, overflow: 'hidden', opacity: createTextOpacity, borderRadius: 120, justifyContent: 'center', alignItems: 'center' }}>

                                        <LinearGradient
                                            colors={['#d3b474', '#d8bd83']} // Kolorystyka gradientu
                                            start={[0, 0]} // Punkt startowy gradientu (x, y)
                                            end={[1, 0]}   // Punkt końcowy gradientu (x, y)
                                            style={{ height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center', position: 'absolute' }}
                                        ></LinearGradient>

                                        <AppText text={'Nowa Zagadka'} style={{ position: 'absolute', width: 200, zIndex: 1, color: colors.white, fontSize: 16 }} />




                                    </Animated.View>

                                </TouchableOpacity>
                            }



                        </Pressable>

                        {width > 650 && <View style={{ width: '10%', height: '100%', position: 'absolute', right: 20, justifyContent: 'center', alignItems: 'center' }}>

                            <TouchableOpacity style={{ alignItems: 'center' }} onPress={() => scrollToNewPuzzle()}>

                                <MaterialCommunityIcons name='chevron-right' color={colors.white} size={60} style={{ marginBottom: 10 }} />
                                <AppText text={"NASTĘPNA"} gradient={true} gradientSize={15} center={true} />

                            </TouchableOpacity>


                        </View>}

                        {width > 650 && <View style={{ width: '10%', height: '100%', position: 'absolute', left: 20, justifyContent: 'center', alignItems: 'center' }}>

                            <TouchableOpacity style={{ alignItems: 'center' }} onPress={() => scrollToPreviousPuzzle()}>

                                <MaterialCommunityIcons name='chevron-left' color={colors.white} size={60} style={{ marginBottom: 10 }} />
                                <AppText text={"POPRZEDNIA"} gradient={true} gradientSize={15} center={true} />

                            </TouchableOpacity>


                        </View>}


                        <View style={{ width: width > 650 ? width / 2.5 : width - 50, marginVertical: width > 50 ? 30 : 0, overflow: 'hidden' }} >
                            <FlatList

                                data={tasks}

                                keyExtractor={(item) => item.id.toString()}

                                horizontal={true}

                                showsHorizontalScrollIndicator={false}

                                scrollEnabled={false}

                                ref={puzzlesListRef}

                                renderItem={({ item, index }) => {

                                    return (
                                        <>
                                            {index != 3 && <Animated.View style={{ alignItems: 'center' }}>
                                                <Puzzle question={item.question} level={item.level} setIndex={setIndex} position={item.position} solution={item.solution} listRef={puzzlesListRef.current} tasks={tasks} index={index} />
                                            </Animated.View>}

                                            {index == 3 && <Animated.View style={{ alignItems: 'center' }}>

                                                <View style={{ width: width > 650 ? width / 2.5 : width - 50, marginVertical: 30, paddingTop: width > 650 ? 150 : 30, alignItems: 'center' }} >


                                                    <Lottie animationData={require("../assets/animations/move.json")} loop={true} style={{ width: 300, height: 300 }} />

                                                    <AppText text={"Puzzle Rozwiązane!"} gradient={true} gradientSize={width > 650 ? 50 : 30} center={true} />

                                                </View>

                                            </Animated.View>}

                                        </>
                                    )

                                }}

                            />


                            {width < 650 && <View style={{ width: width - 50, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 20, marginRight: 10 }}>


                                <TouchableOpacity style={{ alignItems: 'center' }} onPress={() => scrollToPreviousPuzzle()}>

                                    <MaterialCommunityIcons name='chevron-left' color={colors.white} size={50} style={{ marginBottom: 10 }} />
                                    <AppText text={"POPRZEDNIA"} gradient={true} gradientSize={13} center={true} />

                                </TouchableOpacity>

                                <LinearGradient
                                    colors={['#d3b474', '#d8bd83']} // Kolorystyka gradientu
                                    start={[0, 0]} // Punkt startowy gradientu (x, y)
                                    end={[1, 0]}   // Punkt końcowy gradientu (x, y)
                                    style={{ height: 15, width: 15, marginBottom: 20, borderRadius: 30, marginHorizontal: 25 }}
                                ></LinearGradient>


                                <TouchableOpacity style={{ alignItems: 'center' }} onPress={() => scrollToNewPuzzle()}>

                                    <MaterialCommunityIcons name='chevron-right' color={colors.white} size={50} style={{ marginBottom: 10 }} />
                                    <AppText text={"NASTĘPNA"} gradient={true} gradientSize={13} center={true} />

                                </TouchableOpacity>

                            </View>}

                        </View>



                    </View>



                </View>

                <View style={{ height: width > 650 ? 400 : 450, backgroundColor: colors.black, alignItems: 'center', paddingTop: width < 650 ? 30 : 20, borderTopWidth: 3, borderColor: colors.white }}>

                    <AppText text={"O CO CHODZI?"} gradient={true} gradientSize={width > 650 ? 70 : 55} center={true} />
                    <View style={{ width: width > 650 ? width / 1.5 : width - 20 }}>
                        <AppText text={`Na każdym odcinku Arcymistrz Mateusz Bartel..,`} gradient={true} gradientSize={width > 650 ? 20 : 16} center={true} />



                    </View>

                </View>
                </>*/}
                <View style={{ height: 300, paddingTop: 20, alignItems: 'center', borderTopWidth: 3, borderColor: colors.white }}>
                    <LinearGradient
                        colors={['#FF5733', '#A83279']} // Kolorystyka gradientu
                        start={[0, 0]} // Punkt startowy gradientu (x, y)
                        end={[1, 0]}   // Punkt końcowy gradientu (x, y)
                        style={{ height: 300, width: '100%', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 0 }}
                    ></LinearGradient>


                    <AppText text={"KONTAKT"} style={{ zIndex: 1, fontSize: 50, color: colors.white }} />
                    <AppText text={"kontakt@podcastszachowy.pl"} style={{ zIndex: 1, fontSize: 23, color: colors.white, marginTop: 20 }} />
                </View>




            </Animated.View>
        </ScrollView >


    );
};

const styles = StyleSheet.create({
    textStyle: {

        fontSize: 15,
        fontFamily: "AppFont",

        fontWeight: '700',

        color: colors.black,
        textAlign: 'center',
        width: width > 650 ? width / 2 - 50 : width - 60, height: width > 650 ? 420 : 350
    },
})

export default ChessScreen;