import React, { useEffect, useState } from 'react';
import { View, Animated, Image } from 'react-native';
import colors from '../colors/colors';
import { LinearGradient } from 'expo-linear-gradient';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import AppText from './AppText';

function QuestionAnimation(props) {

    const valueLayout = useState(new Animated.ValueXY({ x: 0, y: 0 }))[0];

    const valueLayout2 = useState(new Animated.ValueXY({ x: 0, y: 0 }))[0];

    return (
        <View style={{ width: 250, height: 200, justifyContent: 'center', alignItems: 'center' }}>



            <View style={{ position: 'absolute' }}>
                <Animated.View style={[valueLayout2.getLayout(), { width: 160, height: 160, backgroundColor: colors.primary, justifyContent: 'center', alignItems: 'center', overflow: 'hidden', borderRadius: 80 }]}>

                    <Image style={{ width: 160, height: 160 }} source={{ uri: "https://pbs.twimg.com/profile_images/823253022868459520/TCdP4coA_400x400.jpg" }} />
                </Animated.View>
            </View>
        </View>
    );
}

export default QuestionAnimation; ''