import React, { useEffect, useState } from 'react';
import { Pressable, View, Animated, TouchableOpacity } from 'react-native';
import { Chessboard } from 'react-chessboard';
import { Chess } from 'chess.js';
import Lottie from "lottie-react";

import colors from '../colors/colors';
import AppText from './AppText';



const width = window.innerWidth;
const height = window.innerHeight;

function Puzzle({ question, position, solution, level, listRef, index, setIndex }) {

    const [game, setGame] = useState(new Chess(position));

    const [answer, setAnswer] = useState('');

    function makeAMove(move) {
        const gameCopy = new Chess(game.fen());
        const result = gameCopy.move(move);
        if (result !== null) {

            // Wydrukuj informacje o ruchu na konsolę
            const piece = gameCopy.get(move.to); // Pobierz figurę z pola docelowego ruchu
            if (piece) {

                setAnswer(gameCopy.fen())
                moveSoultionCard()

            }

        }
        setGame(gameCopy);
        return result;
    }

    function makeRandomMove() {
        const possibleMoves = game.moves({ verbose: true });

        // Wybierz tylko ruchy białych figurek
        const whiteMoves = possibleMoves.filter((move) => move.color === "w");

        if (
            game.game_over() ||
            game.in_draw() ||
            whiteMoves.length === 0
        ) {
            return; // Wyjście, jeśli gra się skończyła lub brak ruchów białych
        }

        const randomIndex = Math.floor(Math.random() * whiteMoves.length);
        makeAMove(whiteMoves[randomIndex]);
    }

    function onDrop(sourceSquare, targetSquare) {
        // Sprawdź, czy figura jest białą figurą
        const piece = game.get(sourceSquare);
        if (!piece || piece.color !== "w") {
            return false;
        }
        const move = makeAMove({
            from: sourceSquare,
            to: targetSquare,
            promotion: "q", // Zawsze promuj do królowej dla uproszczenia
        });

        // Niepoprawny ruch
        if (move === null) {
            // Figura wraca na pole źródłowe
            makeAMove({
                from: targetSquare,
                to: sourceSquare,
                promotion: "q",
            });
            return false;
        }
        setTimeout(makeRandomMove, 200);
        return true;
    }

    const solutionLayout = useState(new Animated.ValueXY({ x: 0, y: 0 }))[0];

    const moveSoultionCard = () => {

        setTimeout(function () { Animated.timing(solutionLayout, { toValue: { x: 0, y: width > 650 ? -300 : -500 }, duration: 300, useNativeDriver: false }).start(); }, 150);

        setTimeout(function () { Animated.timing(solutionLayout, { toValue: { x: 0, y: 0 }, duration: 300, useNativeDriver: false }).start(); }, 2050);

        setTimeout(function () { scrollToNewPuzzle() }, 2550);
        setTimeout(function () { setIndex(index + 1) }, 3050);
    }

    const scrollToNewPuzzle = () => {

        listRef.scrollToIndex({ animated: true, index: index + 1 })

    }

    return (
        <View style={{ alignItems: 'center', zIndex: 1 }}>

            <View style={{ width: 300, justifyContent: 'center', height: 140, position: 'absolute', bottom: width > 650 ? -140 : -500, zIndex: 1 }}>


                <Animated.View style={[solutionLayout.getLayout(), { width: 300, overflow: 'hidden', height: 140, bordeRadius: 'center', borderRadius: 30, backgroundColor: '#a69c9c', paddingTop: 10 }]}>


                    <View style={{ width: '100%', height: '100%', alignItems: 'center' }}>

                        <Lottie animationData={require("../assets/animations/move.json")} loop={true} style={{ width: 100, height: 100 }} />

                        <AppText text={answer === solution ? "BRAWO!" : 'ZŁA ODPOWIEDŹ!'} gradient={true} gradientSize={20} center={true} />



                    </View>

                </Animated.View>

            </View>


            <AppText text={level} gradient={true} gradientSize={width > 650 ? 30 : 25} center={true} />

            <AppText text={question} style={{ fontSize: 20, color: colors.white, marginTop: 5 }} center={true} />



            <View style={{ width: width > 650 ? width / 2.5 : width - 50, height: width > 650 ? width / 2.5 : width - 50, marginVertical: 30 }} >
                <Chessboard position={game.fen()} onPieceDrop={onDrop} />
            </View>



        </View>
    );
}

export default Puzzle;