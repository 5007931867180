import React, { useEffect, useState } from 'react';
import { TouchableOpacity , Dimensions , Animated } from 'react-native';
import colors from '../colors/colors';
import { View } from 'react-native-web';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import AppText from './AppText';
const {width, height} = Dimensions.get('screen');

function Box2({index,number , onClick , color ,textColor , text}) {

    
        const animation =  new Animated.Value((width/1.50)/2);
       
const [isFull,setIsFull] = useState();

      const startRotating = () => {
          
        Animated.timing(animation,{toValue : (width/1.50),duration : 600,useNativeDriver : false}).start();
       
      }
      const stopRotating = () => {
          
        Animated.timing(animation,{toValue :((width/1.50)/2),duration : 600,useNativeDriver : false}).start();
       
      }

      const [zIndex ,seZtIndex] = useState(0);

      const clicked = () => {

  
  
  setIsFull(!isFull)
console.log(index)


      }

useEffect(() => {

  
    if(isFull)
   seZtIndex(1)
 else
seZtIndex(0);


},[isFull])


useEffect(() => {

  
    if(isFull)
    startRotating();
 else
stopRotating();


},[zIndex])

    return (

      
      <TouchableOpacity onPress={clicked} style={{  right : 0   , zIndex : index , position : 'absolute'  }}>



      <View style={{}}>
         
      
      
         <Animated.View style={{width : animation , height : height/3 , alignItems : 'center' , justifyContent : 'center', backgroundColor : color }}>
      
      { index == number &&  <MaterialCommunityIcons name='chevron-right' size={50} style={{position : 'absolute' , left : 20 , top : ((width/6)/2)}} color={colors.white}/>}
      
      <AppText text={text} style={{color : textColor , fontSize : 40 }}/>
      
              </Animated.View>
              </View>
      
              </TouchableOpacity>
    );
}

export default Box2;