import React from 'react';
import { TextInput, View, StyleSheet, Platform, TouchableOpacity, Text, KeyboardAvoidingView } from 'react-native';
import colors from '../colors/colors';
import { Button } from 'react-native';
import { useFonts } from 'expo-font';

function AppTextInput({ icon, placeHolderName, onChangeEvent, sizeProp, text, style, onPressProp, showSend, lottie, lottieStyle, referance, color, textInputStyle }) {

    const [fontsLoaded] = useFonts({
        'AppFont': require("../fonts/WebsiteFont.ttf"),
    });


    return (
        <TouchableOpacity style={[{
            backgroundColor: color ? color : '#a69c9c',
            width: sizeProp ? sizeProp + '%' : 300,
            padding: 15,

            borderRadius: 10,
            alignItems: 'center',
            justifyContent: 'center',
            height: 60,

        }, style]} onPress={onPressProp}>
            <TextInput style={[styles.textStyle, textInputStyle]} placeholder={placeHolderName} onChangeText={onChangeEvent} ref={referance}>




            </TextInput>

            {lottie && <LottieView autoPlay={true} speed={1} loop={true} style={[{ width: 50, height: 50, position: 'absolute', right: 10, bottom: 5 }, lottieStyle]} source={lottie} />}

        </TouchableOpacity>
    );
}
const styles = StyleSheet.create({

    textStyle: {

        fontSize: 16,
        fontFamily: 'AppFont',
        fontWeight: 'bold',
        textAlign: 'center'
    },

})

export default AppTextInput;