import React from 'react';
import { TextInput, View, StyleSheet, Platform, TouchableOpacity, Text, KeyboardAvoidingView } from 'react-native';
import colors from '../colors/colors';
import { Button } from 'react-native';
function LargeTextInput({ icon, placeHolderName, onChangeEvent, sizeProp, text, style, onPressProp, showSend, lottie, lottieStyle, referance }) {
    return (
        <TouchableOpacity style={[{
            backgroundColor: '#a69c9c',
            width: sizeProp ? sizeProp + '%' : 300,
            padding: 15,

            borderRadius: 50,
            alignItems: 'center',
            justifyContent: 'center',
            height: 250,

        }, style]} onPress={onPressProp}>
            <TextInput style={[styles.textStyle]} placeholder={placeHolderName} onChangeText={onChangeEvent} ref={referance}>




            </TextInput>


        </TouchableOpacity>
    );
}
const styles = StyleSheet.create({

    textStyle: {

        fontSize: 18,
        fontFamily: Platform.OS === 'android' ? "Roboto" : "Avenir",
        fontWeight: 'bold',
        textAlign: 'center',
    },

})

export default LargeTextInput;