import firebase from 'firebase/compat/app';
import 'firebase/compat/auth'
import { getStorage } from 'firebase/storage';
import { getDatabase, ref } from 'firebase/database';
import { getMessaging } from "firebase/messaging";
import { GoogleAuthProvider } from "firebase/auth";
import Constants from 'expo-constants';

const firebaseConfig = {
    apiKey: Constants.manifest.extra.API_KEY,
    authDomain: Constants.manifest.extra.AUTH_DOMAIN,
    projectId: "podcast-szahowy",
    storageBucket: "podcast-szahowy.appspot.com",
    messagingSenderId: Constants.manifest.extra.MESSEGING_SENDER_ID,
    appId: Constants.manifest.extra.APP_ID,
    measurementId: Constants.manifest.extra.MESEURMENT_ID
}

if (firebase.apps.length === 0) {
    firebase.initializeApp(firebaseConfig);
}

console.log(Constants.manifest.extra.MY_API_KEY)

const db = getDatabase();
const storage = getStorage();
const reference = ref;
const googleProvider = new GoogleAuthProvider();
export { db, firebase, storage, getMessaging, reference, googleProvider };