import React from 'react';
import { View, StyleSheet, Text, Masked } from 'react-native';
import colors from '../colors/colors';
import { useFonts } from 'expo-font';
import { LinearGradient } from 'react-text-gradients'


function AppText({ style, text, lines, gradient, gradientSize, center }) {

    const [fontsLoaded] = useFonts({
        'AppFont': require("../fonts/WebsiteFont.ttf"),
    });

    return (
        <>
            {!gradient && <Text style={[styles.textStyle, style]} numberOfLines={lines}>{text}</Text>}

            {gradient && <LinearGradient gradient={['to left', '#d3b474 ,#d8bd83']} style={{
                fontSize: gradient ? gradientSize : 16,

                fontWeight: '410',
                textAlign: center ? 'center' : 'left',
                fontFamily: 'AppFont',
                width: '100%'
            }}>{text}</LinearGradient>}
        </>
    );
}
const styles = StyleSheet.create({
    textStyle: {

        fontSize: 16,

        fontWeight: '410',
        textAlign: 'center',
        fontFamily: 'AppFont'
    },
})
export default AppText;