import React from 'react';
import { View, Dimensions } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { TouchableOpacity } from 'react-native';
import colors from '../colors/colors';
import AppText from './AppText';

function TabButton({ width, onPress, text, border }) {
    return (
        <TouchableOpacity style={{ height: 50, justifyContent: 'center', alignItems: 'center', overflow: 'hidden', width: width, marginHorizontal: 15, borderRadius: 10 }} onPress={onPress}>
            {!border && <LinearGradient
                colors={['#d3b474', '#d8bd83']} // Kolorystyka gradientu
                start={[0, 0]} // Punkt startowy gradientu (x, y)
                end={[1, 0]}   // Punkt końcowy gradientu (x, y)
                style={{ height: 55, width: width, justifyContent: 'center', alignItems: 'center', position: 'absolute' }}
            ></LinearGradient>}

            <AppText text={text} style={{ width: '80%', fontSize: 15, color: colors.white, zIndex: 1, fontWeight: "900" }} />
        </TouchableOpacity>
    );
}

export default TabButton;