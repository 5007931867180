import React, { useEffect, useState } from 'react';
import { View, Dimensions, Animated, Image, Text } from 'react-native';
import colors from '../colors/colors';
import { TouchableOpacity } from 'react-native';
import Box from '../components/Box';
import { ScrollView } from 'react-native';
import Box2 from '../components/Box2';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import AppText from '../components/AppText';
const { width, height } = Dimensions.get('screen');

function MainScreen(props) {

  const valueLayout = useState(new Animated.ValueXY({ x: 0, y: 0 }))[0];

  const moveCard3 = () => {


    setTimeout(function () { Animated.timing(valueLayout, { toValue: { x: 0, y: width }, duration: 500, useNativeDriver: false }).start(); }, 100);
  }
  console.log("test")


  return (
    <ScrollView style={{ flex: 1 }} onScroll={() => moveCard3()}>

      <View style={{ alignItems: 'center', }}>


        {width > 650 && <View style={{ alignItems: 'center', width: '100%', height: height, }}>

          <Image style={{ width: '100%', height: height, position: 'absolute' }} blurRadius={1} source={{ uri: "https://pzszach.pl/wp-content/uploads/2018/06/Zdobywca-I-miejsca-arc.-Mateusz-Bartel-1.jpg" }} />

          <View style={{ width: width / 4, height: '60%', justifyContent: 'center', position: 'absolute', left: 0 }}>

            <View style={{ position: 'absolute', right: 0 }}>


              <AppText text={"Podcasy"} style={{ fontSize: 60, fontWeight: "900" }} />
              <AppText text={"Podcasy"} style={{ fontSize: 60 }} />
            </View>

          </View>


        </View>}

      </View>





    </ScrollView >
  );
}

export default MainScreen;