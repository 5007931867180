import React from 'react';
import { View, Dimensions, Image } from 'react-native';
import colors from '../colors/colors';
const { width, height } = Dimensions.get('screen');
import { LinearGradient } from 'expo-linear-gradient';
import AppText from './AppText';
import { TouchableOpacity } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';

function NewestEpisode(props) {
    return (
        <View style={{ width: '50%', height: 200, opacity: 1, flexDirection: 'row', padding: 30, alignItems: 'center' }}>

            <LinearGradient
                colors={['#d3b474', '#d8bd83']} // Kolorystyka gradientu
                start={[0, 0]} // Punkt startowy gradientu (x, y)
                end={[1, 0]}   // Punkt końcowy gradientu (x, y)
                style={{ height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center', position: 'absolute' }}
            ></LinearGradient>

            <Image style={{ width: 160, height: 160, marginLeft: 20 }} source={require("../assets/logo.png")} />
            <View style={{ width: '50%', height: 160, marginLeft: 20 }}>

                <AppText text={"Odcinek 1"} style={{ fontSize: 30, textAlign: 'left', fontWeight: "900", color: colors.white }} />
                <AppText text={"opis odcinka 1"} style={{ fontSize: 16, textAlign: 'left', fontWeight: "900", color: colors.white }} />

            </View>

            <TouchableOpacity style={{ width: 60, height: 60, position: 'absolute', right: 30, justifyContent: 'center', alignItems: 'center', borderRadius: 50, overflow: 'hidden' }}>

                <LinearGradient
                    colors={['#FF5733', '#A83279']} // Kolorystyka gradientu
                    start={[0, 0]} // Punkt startowy gradientu (x, y)
                    end={[1, 0]}   // Punkt końcowy gradientu (x, y)
                    style={{ height: 60, width: '100%', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 0 }}
                ></LinearGradient>
                <MaterialCommunityIcons name='play' size={30} color={colors.white} style={{ zIndex: 1 }} />

            </TouchableOpacity>
        </View>
    );
}

export default NewestEpisode;